<template>
  <div>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      name="ประเภทหวย"
    >
      <b-form-group
        :label="hideLabel ? null : `ประเภทหวย ${required ? '*' : ''}`"
        :label-cols-lg="hideLabel ? null : '3'"
        :label-cols-sm="hideLabel ? null : '12'"
      >
        <multiselect
            v-model="selectedType"
            :clear-on-select="false"
            :options="lottoType"
            :placeholder="hasSelectAll ? 'เลือกทุกประเภท' : 'เลือกประเภทหวย'"
            label="label"
            track-by="name"
        />
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
import {LottoGameTypeEnum} from '@src/constants/lotto';

export default {
  name: 'LottoTypeSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedType: null,
    }
  },
  computed: {
    lottoType() {
     return [
       { name: LottoGameTypeEnum.THAI_GOVERNMENT, label: 'หวยรัฐบาล' },
       { name: LottoGameTypeEnum.THAI_BANK, label: 'หวยธนาคาร' },
       { name: LottoGameTypeEnum.THAI_STOCK, label: 'หวยหุ้นไทย' },
       { name: LottoGameTypeEnum.FOREIGN_STOCK, label: 'หวยหุ้นต่างประเทศ' },
       { name: LottoGameTypeEnum.FOREIGN_LOTTO, label: 'หวยต่างประเทศ' },
       { name: LottoGameTypeEnum.SET, label: 'หวยชุด' },
       { name: LottoGameTypeEnum.YEEKEE, label: 'หวยยี่กี' },
     ]
    },
  },
  watch: {
    selectedType(val) {
      this.$emit('input', val?.name || null)
    },
  },
  created() {
    if (this.value) {
      this.selectedType = this.lottoType.find((type) => type.name === this.value) || null
    }
  }
}
</script>
